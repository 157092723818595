import React from 'react';
import Helmet from 'react-helmet';
import Blocks from '~/components/blocks';
import PostsGrid from '~/components/portfolio-grid';

// Main Component
const Portfolio = ({ pageContext, location }) => {
  const { title, blocks } = pageContext;

  return (
    <>
      {/* Head Data */}
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {/* Page Content */}
      <Blocks blocks={blocks} />

      <PostsGrid location={location} />
    </>
  );
};

export default Portfolio;
