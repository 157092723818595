import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import parse from 'html-react-parser';

const Post = ({ data }) => {
  const image = data.image?.localFile?.childImageSharp.fixed;
  const location = data.acf?.location;
  const year = data.acf?.year;
  const status = data.acf?.status;

  return (
    <Link
      className="post-card"
      to={`/portfolio/${data.slug}`}
      state={{ fromPortfolio: true }}
    >
      {image && (
        <div className="post-card-image-wrapper">
          <Img
            className="post-card-image"
            fixed={image}
            style={{ position: 'absolute', width: '100%', height: '100%' }}
          />
        </div>
      )}
      <div className="post-card-caption">
        <h3 className="post-card-title">{parse(data.title)}</h3>
        <span className="meta">
          {location}
          {location && (year || status === 'in_progress') && ' | '}
          {status === 'in_progress' ? 'In Progress' : `Completed ${year}`}
        </span>
      </div>
    </Link>
  );
};

export default Post;
