import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Picky } from 'react-picky';
import Post from './grid-item';
import 'react-picky/dist/picky.css';
import './grid.scss';

export default () => {
  const data = useStaticQuery(graphql`
    query portfolioQuery {
      projects: allWordpressWpWork {
        nodes {
          wordpress_id
          title
          slug
          image: featured_media {
            localFile {
              childImageSharp {
                fixed(width: 618, height: 412) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          acf {
            location
            year
            status
          }
          types: project_types {
            name
            slug
            wordpress_id
          }
          industries {
            name
            slug
            wordpress_id
          }
        }
      }
      types: allWordpressWpProjectTypes {
        nodes {
          name
          slug
          wordpress_id
        }
      }
      industries: allWordpressWpIndustries {
        nodes {
          name
          slug
          wordpress_id
        }
      }
    }
  `);

  // projects
  const unorderedProjects = data.projects?.nodes;
  const projects = unorderedProjects.sort((a, b) => {
    if (a.acf.status === 'in_progress' && b.acf.status === 'in_progress')
      return 0;
    if (a.acf.status === 'in_progress') return -1;
    if (b.acf.status === 'in_progress') return 1;
    return b.acf.year - a.acf.year;
  });

  // filters
  const types = data.types?.nodes;
  const industries = data.industries?.nodes;

  const displayedIndustries = industries.filter(a =>
    projects.some(b => b.industries.some(c => c.slug === a.slug))
  );

  const portfolioRef = React.createRef({});

  const [selectedTypes, setTypeFilters] = useState([]);
  const [selectedIndustries, setIndustryFilters] = useState([]);
  const [selectedStatus, setStatusFilters] = useState([]);

  const hasType = selectedTypes.length > 0;
  const hasIndustry = selectedIndustries.length > 0;
  const hasStatus = !!selectedStatus.slug;

  const handleTypeChange = selected => {
    setTypeFilters(selected);
  };
  const handleIndustryChange = selected => {
    setIndustryFilters(selected);
  };
  const handleStatusChange = selected => {
    setStatusFilters(selected);
  };
  const clearTypes = () => {
    setTypeFilters([]);
  };
  const clearIndustries = () => {
    setIndustryFilters([]);
  };
  const clearStatus = () => {
    setStatusFilters([]);
  };

  const [posts, setPosts] = useState(projects);

  useEffect(() => {
    if (selectedTypes || selectedIndustries) {
      let filteredPosts = projects.filter(p => {
        return (
          (hasType
            ? p.types?.some(a => selectedTypes.some(z => a.slug === z.slug))
            : true) &&
          (hasIndustry
            ? p.industries.some(a =>
                selectedIndustries.some(z => a.slug === z.slug)
              )
            : true) &&
          (hasStatus && selectedStatus.slug !== 'all'
            ? p.acf.status === selectedStatus.slug
            : true)
        );
      });
      setPosts(filteredPosts);
    } else {
      setPosts(projects);
    }
    // portfolioRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [projects, selectedTypes, selectedIndustries, selectedStatus]);

  return (
    <section ref={portfolioRef} className="portfolio">
      <div className="sticky-filters">
        <div className="container">
          <div className="du__filters">
            <span className="du__filter-label label">Filter by:</span>
            <div className="du__filter">
              {hasType && (
                <span className="du__filter-label label">Project Type</span>
              )}
              <Picky
                className={hasType ? 'active' : null}
                placeholder="Project Type"
                value={selectedTypes}
                valueKey="slug"
                labelKey="name"
                options={types}
                multiple={true}
                numberDisplayed={2}
                onChange={handleTypeChange}
              />
              {hasType && (
                <button className="clear-filter" onClick={clearTypes}>
                  &times;
                </button>
              )}
            </div>
            <div className="du__filter">
              {hasIndustry && (
                <span className="du__filter-label label">Industry</span>
              )}
              <Picky
                className={hasIndustry ? 'active' : null}
                placeholder="Industry"
                value={selectedIndustries}
                valueKey="slug"
                labelKey="name"
                options={displayedIndustries}
                multiple={true}
                numberDisplayed={2}
                onChange={handleIndustryChange}
              />
              {hasIndustry && (
                <button className="clear-filter" onClick={clearIndustries}>
                  &times;
                </button>
              )}
            </div>
            <div className="du__filter">
              {hasStatus && (
                <span className="du__filter-label label">Progress</span>
              )}
              <Picky
                className={hasStatus ? 'active' : null}
                placeholder="Progress"
                value={selectedStatus}
                valueKey="slug"
                labelKey="name"
                options={[
                  { slug: 'completed', name: 'Completed' },
                  { slug: 'in_progress', name: 'In Progress' },
                ]}
                multiple={false}
                numberDisplayed={2}
                onChange={handleStatusChange}
              />
              {hasStatus && (
                <button className="clear-filter" onClick={clearStatus}>
                  &times;
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="posts-grid">
        {posts.length > 0 ? (
          posts.map(post => <Post key={post.id} data={post} />)
        ) : (
          <h5 className="posts-grid-no-results">No results.</h5>
        )}
      </div>
    </section>
  );
};
